import { useState } from "react"
import "./Chat.scss"

const Chat = ({ messages, onSendMessage }) => {

    const [message, setMessage] = useState('')

    const submitHandler = (event) => {
        event.preventDefault();
        if (message) {
            onSendMessage(message)
            setMessage('')
        }
    }

    return (
        <div className="chat">
            <ul className="message-list">
                {messages.map((mes, index) => (
                    <li key={index}>{mes}</li>
                ))}
            </ul>
            <form className="message-form" onSubmit={submitHandler}>
                <input type="text" className="textbox form-control"
                    autoFocus={true}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                />
                <button type="submit" className="btn btn-primary">发送消息</button>
            </form>
        </div>
    )
}

export default Chat
