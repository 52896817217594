import './App.scss';
import Login from './components/Login'
import Game from './components/Game'
import Chat from './components/Chat';
import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Stage } from '@pixi/react';

let interval_id = 0
let timestamp = 0

const App = () => {

    const [logined, setLogined] = useState(false)
    const [loginFailed, setLoginFailed] = useState(false)
    const [pingMicroSec, setPingMicroSec] = useState(0)
    const [chatMessages, setChatMessages] = useState([])

    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    const socketUrl = isDev ? 'ws://127.0.0.1:8282' : 'wss://noa.iroul1983.com/wss'
    const pingInterval = 25000

    const socketOpenHander = (event) => {
        if (!interval_id) {
            interval_id = setInterval(ping, pingInterval)
        }
        console.log('on socket open')
    }

    const socketCloseHander = (event) => {
        console.log('on socket close')
        if (interval_id) {
            clearInterval(interval_id)
        }
    }

    const socketErrorHander = (event) => {
        console.log('on socket error')
        if (interval_id) {
            clearInterval(interval_id)
        }
    }

    const socketMessageHander = (event) => {

        const data = JSON.parse(event.data)
        console.log('on socket message: ', data)

        switch (data.type) {

            case 'pong':
                const responseTime = (new Date).getTime()
                if (timestamp && responseTime > timestamp) {
                    setPingMicroSec(responseTime - timestamp)
                }
                break

            case 'login-success':
                setLogined(true)
                setLoginFailed(false)
                break;

            case 'login-failed':
                setLoginFailed(true)
                break;

            case 'chat':
                const message = data.message;
                if (message) {
                    setChatMessages(prev => [...prev, message])
                    // console.log(chatMessages)
                }
        }
    }

    const { sendMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: socketOpenHander,
        onClose: socketCloseHander,
        onMessage: socketMessageHander,
        onError: socketErrorHander,
    })

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]

    const ping = () => {
        timestamp = (new Date).getTime()
        const message = { type: 'ping' }
        sendMessage(JSON.stringify(message))
    }

    const loginHander = (email, password) => {
        const message = { type: 'login', email, password }
        sendMessage(JSON.stringify(message))
    }

    const sendChatMessageHander = (chatMessage) => {
        const message = { type: 'chat', message: chatMessage }
        sendMessage(JSON.stringify(message))
    }

    return (
        <div className="App">
            {!logined &&
                <Login
                    loginFailed={loginFailed}
                    onLogin={loginHander}
                />
            }
            <div className='status'>
                WebSocket: {connectionStatus} <br />
                Server Response: {pingMicroSec ? pingMicroSec + 'ms' : '--'}
            </div>
            <Chat
                messages={chatMessages}
                onSendMessage={sendChatMessageHander}
            />
            <Stage>
                <Game />
            </Stage>
        </div>
    )
}

export default App
