import './Login.scss'

import React, { useState, useEffect } from 'react'

const Login = ({ loginFailed, onLogin }) => {

    // 关联表单输入内容
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

    const [userEmail, setUserEmail] = useState(isDev ? 'iroul1983@163.com' : '')
    const [userPassword, setUserPassword] = useState(isDev ? 'ilovehuihui' : '')

    const userEmailChangeHandler = (e) => {
        setUserEmail(e.target.value.trim())
    }

    const userPasswordChangeHandler = (e) => {
        setUserPassword(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        onLogin(userEmail, userPassword)
    }

    return (

        <div className="login">
            <div className="card shadow">
                <div className="card-body">
                    <form className="login-form" method="POST" onSubmit={submitHandler}>
                        <div className="form-row">
                            <label htmlFor="email">电子邮件</label>
                            <input id="email" type="email" name="email"
                                className="form-control"
                                value={userEmail}
                                onChange={userEmailChangeHandler}
                            />
                        </div>
                        <div className="form-row">
                            <label htmlFor="password">密码</label>
                            <input id="password" type="password" name="password"
                                className="form-control"
                                value={userPassword}
                                onChange={userPasswordChangeHandler}
                            />
                        </div>
                        <div className="form-row">
                            <button id="btn-login" type="submit" className="btn btn-primary">
                                登 录
                            </button>
                            {loginFailed &&
                                <span className="login-failed">登陆失败</span>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Login

